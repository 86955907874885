import React, {useState, useEffect} from 'react';
import {Map as MapLibre, NavigationControl} from 'react-map-gl/maplibre';
import {MapProps} from './Map';
import {BoundsInput} from '../graphql-types';
import Text from './Text';
import styled from 'styled-components';
import colors from '../constants/colors';
import {convertBoundsInputToRegion} from '../utils';
import {useMapLocation} from '../hooks/useMapLocationContext';
import Constants from 'expo-constants';
import location from '../constants/location';

export default function Map({children, onSetBounds, ...props}: MapProps) {
  const [bounds, setBounds] = useState<BoundsInput>(props.bounds);
  const {location} = useMapLocation();

  const region = convertBoundsInputToRegion(bounds);
  const [center, setCenter] = useState({
    latitude: region.latitude,
    longitude: region.longitude,
    zoom: 5
  });

  useEffect(() => {
    if (!location) {
      return;
    }

    setCenter({
      latitude: location.latitude,
      longitude: location.longitude,
      zoom: 5
    });
  }, [location]);

  return (
    <div style={{height: 'calc(100vh - 86px)', width: '100%'}}>
      <MapLibre
        {...center}
        onMove={evt => setCenter(evt.viewState)}
        mapStyle="https://tiles.openfreemap.org/styles/liberty"
      >
        {children}
        <NavigationControl position='bottom-right' />
      </MapLibre>
    </div>
  );
}
