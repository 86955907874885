import React from 'react';
import {MarkerProps} from './Marker';
import styled from 'styled-components';
import {Marker as MapLibreMarker, Popup} from 'react-map-gl/maplibre';

const Icon = styled.img`
  width: 25px;
`;

const Callout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -125%);
`;

export default function Marker({
  lat,
  lng,
  title,
  onPress,
  icon,
  callout,
  showCallout,
}: MarkerProps) {
  return (
    <MapLibreMarker
      longitude={lng}
      latitude={lat}
      onClick={onPress}
    >
      {callout && showCallout ? <Callout>{callout}</Callout> : null}
      <Icon src={icon as string} />
    </MapLibreMarker>
  );
}
